<template>
  <div id="school-standards">
    <b-container class="base-container-x" style="min-height:500px;background-color:#fff">
      <div v-if="loading === true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading === false" id="banner" class="banner-inner eyep">
      </div>
      <div v-if="loading === true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading === false" class="col-12 mb-5 pt-4">
        <h2 class="text-center font-weight-bold">{{ $t('Exchange Yoga Experience Program - EYEP') }}</h2>
        <p class="mt-5">{{ $t('We believe one should never stop learning and practicing and that is the reason we provide FREE continuous yoga education called') }} <b><i>{{ $t('“Exchange Yoga Experience Program ” (EYEP)') }}</i></b> {{ $t('for our Certified Yoga Teachers (CYT) through our Certified Yoga Schools and Divisions worldwide.') }}</p>
        <div class="mt-5">
          <h5 class="text-center">{{ $t('Learn more about EYEP') }}</h5>
          <b-row class="mt-3">
            <b-col cols="12" sm="2" class="d-flex justify-content-center align-items-center">
              <b-img class="w-100 d-none d-sm-block" :src="PUBLIC_PATH + 'img/certificate/EYEP.png'" />
              <b-img class="w-50 d-block d-sm-none mb-3" :src="PUBLIC_PATH + 'img/certificate/EYEP.png'" />
            </b-col>
            <b-col cols="12" sm="10">
              <p>{{ $t('EYEP is WYA Continuous Yoga Education that allows our Certified Yoga Teacher (CYT) to continue their education up to 3 years after completion of yoga training organized by Certified Yoga Schools / Divisions worldwide free of charge. (Terms and Conditions applied) During this period, they will be able to rejoin training in the same level for brush-up and enhance proficiency. (Terms and Conditions applied)') }}</p>
            </b-col>
          </b-row>
        </div>
        <div>
          <p class="font-weight-bold">{{ $t('Who can receive the continuous yoga education with WYA?') }}</p>
          <p>{{ $t('Only those who completed the training with Certified Yoga School (CYS) and have received CYT certification with valid WYA teacher ID are able to get Continuous Free Yoga Education standards from the organization.') }}</p>
          <p class="font-weight-bold">{{ $t('How to receive free yoga teacher training with WYA?') }}</p>
          <p>{{ $t('In order to join our free yoga education program, CYT members must present their') }} <i>“<u>{{ $t('WYA Teacher ID Number') }}</u>”</i> {{ $t('(located on the top right corner on CYT certificate) to the school they would like to rejoin the training. The school will then check the validation of “WYA Teacher ID” Online published on our') }} <router-link :to="{ name: 'searchDirectory', query: { type: 'teacher' } }">{{ $t('International Yoga Teacher Directory.') }}</router-link></p>
          <p class="text-gray-light"><i>* {{ $t('If any Registered Yoga Teachers (RTY) would like to join WYA continuous yoga education, they can apply the training with 15 % discount from the original course fee.') }}</i></p>
          <p class="font-weight-bold">{{ $t('How can we know which schools are the service providers of WYA continuous yoga education?') }}</p>
          <p>{{ $t('Simply look for the school with') }} <router-link :to="{ name: 'CYS' }">{{ $t('CYS designation.') }}</router-link> {{ $t('They are WYA authorized school who work alongside with') }} <a href="https://www.wyayoga.org/" target="_blank" class="text-primary-dark"><u>{{ $t('World Yoga Alliance') }}</u></a> {{ $t('in providing high standard of yoga teacher training, promoting and supporting the integrity and diversity of yoga teaching, with a common vision in maintaining and sharing yoga knowledge and its traditions around the world.') }}</p>
          <p class="font-weight-bold">{{ $t('I am a CYT. Where can I find the training for my continuous education?') }}</p>
          <p>{{ $t('You can either search for training on our') }} <router-link :to="{ name: 'searchDirectory', query: { type: 'event' } }">{{ $t('Directory of Training & Event') }}</router-link> {{ $t('or you can visit each CYS School Profile and contact them directly for an updated training schedule.') }}</p>
          <p class="font-weight-bold">{{ $t('I am already RSY. How can I become CYS providing this continuous education program to the community?') }}</p>
          <p>{{ $t('Please visit our') }} <router-link :to="{ name: 'CYS' }">{{ $t('Standards for CYS') }}</router-link> {{ $t('for more information to upgrade your school as Certified Yoga School.') }}</p>
          <p class="font-weight-bold">{{ $t('') }}</p>
          <p class="font-weight-bold">{{ $t('') }}</p>
          <p class="font-weight-bold">{{ $t('') }}</p>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'ExchangeYogaExperienceProgram',
  title: ' | Exchange Yoga Experience Program',
  data () {
    return {
      loading: true
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  #school-standards {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
</style>
